import { React, Fragment, useState, useEffect } from "react";

import Title from "../Title";
import DashboardHeader from "../components/layout/DashboardHeader";
import { Link, useParams } from "react-router-dom";
import "./LifeInsuranceDetails.css";
import TermPlanProducts from "./data/TermPlanProducts";
import Global from "../Global";
import formatAmount from "indian-currency-formatter";
import EditBasicInfo from "./components/EditBasicInfo";
import EditPlanDetails from "./components/EditPlanDetails";
import EditAddOns from "./components/EditAddOns";
import { ageCalculate, inWords, get_gender, db_date, paymentFrequencyList, DBOptionList } from "../components/global";
import Assistant from "./components/Assistant";
//import { getQuoteNew } from "./life-insurance/HdfcFunctions";
import Swal from "sweetalert2";
import http from "../http";
import { params_hdfc_life_getQuoteFinalised, params_icici_life_getQuoteFinalised } from "./data/params/hdfc";
import EditPayFor from "./components/EditPayFor";
import Loader from "../components/Loader";
import { Modal, ModalBody } from 'react-bootstrap';
import GetRiderDescription from "./components/GetRiderDescription";
//import { icici_data } from "./icici-reponse";

export default function LifeInsuranceDetails() {



  //PPR_ADC Rider OR PPR_PAC Rider to be allowed both are not allowed
  const initialCheckboxesHDFC = {
    INAD: true,
    PPR_PAC: false,
    HPR_CC: false,
    PPR_ADC: true,
    HPR_CCI: true,
  };

  const initialCheckboxesICIC = {
    ADBU: true,
    CIBF: true,

  }

  const { CarrierCode } = useParams();
  const [checkboxes, setCheckboxes] = useState(CarrierCode === 'HDFC' ? initialCheckboxesHDFC : initialCheckboxesICIC);





  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [pageTitle, setPageTitle] = useState("Term Plan: Quote");
  // eslint-disable-next-line
  const [SIPInvestmentAmount, setSIPInvestmentAmount] = useState(350);

  const [uncheckedIciciAddon, setUncheckedIciciAddon] = useState([]);

  const [packages, setPackages] = useState(TermPlanProducts.filter((obj) => obj.CarrierCode === CarrierCode)[0]);
  // eslint-disable-next-line/* 
  /*const [checkedIciciAddon, setCheckedIciciAddon] = useState(CarrierCode === 'HDFC' ? ['INAD', 'PPR_PAC', 'PPR_CC', 'C4CI'] : ['ADBU', 'CIBF']); */
  const [checkedIciciAddon, setCheckedIciciAddon] = useState(CarrierCode === 'HDFC' ? ['INAD', 'PPR_PAC', 'HPR_CCI'  /* 'HPR_CC', 'PPR_ADC',  'HPR_CCI' */] : ['ADBU', 'CIBF']);


  useEffect(() => {
    setPackages(TermPlanProducts.filter((obj) => obj.CarrierCode === CarrierCode)[0]);
  }, [CarrierCode])


  const lifeCovers = {
    0: 'Life',
    1: 'Life Plus',
    2: 'Life and Health',
    3: 'All in One'
  };

  const [form_data, setFormData] = useState(localStorage.getItem('form_data_term_plan') ? JSON.parse(localStorage.getItem('form_data_term_plan'), (key, value) => {
    if (value === 'true' || value === true) return true;
    else if (value === 'false' || value === false) return false;
    else if (value === '') return '';
    else if (!isNaN(value)) return parseInt(value);
    return value;
  }) : {});

  /* 
  
    const [default_cover_till, set_cover_till] = useState();
    useEffect(()=>{ 
  
        if( typeof default_cover_till !== Number){
          set_cover_till(form_data.cover_till);
          console.log("default_cover_till: ", default_cover_till, form_data); 
        }else{
          alert(typeof default_cover_till);
        } 
    }, [default_cover_till]) */


  //eslint-disable-next-line
  const [warningshow, setWarningshow] = useState(false);
  const [warningMessage, setWarningMessage] = useState();
  //eslint-disable-next-line
  const [warningTitle, setWarningTitle] = useState("Term Plan Error");

  const [getRiders, setRiders] = useState({});
  const [frequency, setFrequency] = useState(form_data.frequency ? form_data.frequency : 1);
  /***Edit Basic Info start */
  const [editBasicInfoFlag, setEditBasicInfoFlag] = useState(false);
  const [editPlanDetailsFlag, setEditPlanDetailsFlag] = useState(false);
  const [editPayForFlag, setEditPayForFlag] = useState(false);
  const [editAddOnsFlag, setEditAddOnsFlag] = useState(false);

  /***Edit Basic Info end */

  const [getQuoteNewData, setQuoteNewData] = useState({});
  // eslint-disable-next-line
  const [getRidersNodes, setGetRidersNodes] = useState({});

  // eslint-disable-next-line
  const [riderSumInsured, setRiderSumInsured] = useState(form_data.SumInsured ? (form_data.SumInsured / 2) : 1000000);




  // eslint-disable-next-line
  const [accidentAddon, setAccidentAddon] = useState('PPR_PAC');
  // eslint-disable-next-line 
  const [accidentAddonArr, setAccidentAddonArr] = useState(['INAD', 'C4CI', 'PPR_CC', 'CIBF', 'ADHB']);

  const [ropChecked, setRopChecked] = useState(false);
  const [wopciChecked, setWopciChecked] = useState(false);
  const [woptdChecked, setWoptdChecked] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = (checkboxSetter) => (event) => {
    checkboxSetter(event.target.checked);
  };

  // Effect to call getQuoteNew when ropChecked changes
  useEffect(() => {
    getQuoteNew();
    // eslint-disable-next-line
  }, [ropChecked]);

  // Effect to call getQuoteNew when wopciChecked changes
  useEffect(() => {
    getQuoteNew();
    // eslint-disable-next-line
  }, [wopciChecked]);

  // Effect to call getQuoteNew when woptdChecked changes
  useEffect(() => {
    getQuoteNew();
    // eslint-disable-next-line
  }, [woptdChecked]);




  const getQuoteNew = () => {

    let post_data = {};
    if (CarrierCode === 'ICICI') {
      post_data = params_icici_life_getQuoteFinalised;
    } else {
      post_data = params_hdfc_life_getQuoteFinalised;

      post_data.ROP = ropChecked ? "Yes" : "No";
      post_data.WOPCI = wopciChecked ? "Yes" : "No";
      post_data.WOPTotDisability = woptdChecked ? "Yes" : "No"
    }

    //const post_data = params_hdfc_life_getQuoteFinalised; // params_hdfc_life_getQuoteNew; 

    const form_data = localStorage.getItem('form_data_term_plan') ? JSON.parse(localStorage.getItem('form_data_term_plan'), (key, value) => {
      if (value === 'true' || value === true) return true;
      else if (value === 'false' || value === false) return false;
      else if (value === '') return '';
      else if (!isNaN(value)) return parseInt(value);
      return value;
    }) : {};

    let updatedArr = '';
    if (accidentAddon) {
      // eslint-disable-next-line
      updatedArr = [...accidentAddonArr, accidentAddon];
    } else {
      updatedArr = [...accidentAddonArr]
    }


    const c = form_data.cover_till - form_data.age;



    const userTerm = parseInt(c ? c : (85 - parseInt(form_data.age)));
    let case1 = 1;
    console.log(CarrierCode, 'line 198 - form_data.PPT>>>', form_data.PPT, form_data.cover_till, form_data.age, 'c', c, "(!isNaN(parseInt(c)): ", !isNaN(parseInt(c)), 'form_data', form_data);
    //alert("form_data.PPT : " + form_data.PPT);
    if (CarrierCode === 'ICICI') {
      post_data.PPT = form_data.PPT ? form_data.PPT : (!isNaN(parseInt(c)) ? c : (85 - form_data.age));
    }
    if (CarrierCode === 'HDFC') {
      post_data.PPT = form_data.PPT !== "" ? form_data.PPT : (!isNaN(parseInt(c)) ? c : (85 - form_data.age));

      if (form_data.PPT !== "") {
        case1 = 2;
        post_data.PPT = form_data.PPT;

      } else if (!isNaN(parseInt(c))) {
        case1 = 3;
        post_data.PPT = c;
      } else {
        case1 = 4;
        post_data.PPT = (85 - form_data.age);
      }

    }

    console.log('line 207 - form_data.PPT>>>', form_data.PPT, 'c', c, "case", case1, 'post_data:', post_data);

    let riderSumInsured_ = riderSumInsured;
    if (form_data.PPT === 1) {
      riderSumInsured_ = parseInt(form_data.SumInsured) / 20;
    }

    //console.log('line no 165 ', 'riderSumInsured: ', riderSumInsured, "form_data.PPT ", form_data.PPT, " riderSumInsured_ : ", riderSumInsured_);



    setCheckedIciciAddon(checkedIciciAddon);
    console.log("line no 174-  checkedIciciAddon", checkedIciciAddon, ' | getRiders', getRiders);
    /**
    *  for HDFC responsed riders list  - INAD | HPR_CC | PPR_ADC | PPR_PAC | HPR_CCI 
    * 
    *   "PPR_ADC Rider OR PPR_PAC Rider to be allowed both are not allowed."
    *   "HPR_CCI Rider OR HPR_CC Rider to be allowed both are not allowed."
    * 
    */
    console.log("userTerm + form_data.age : " + (parseInt(userTerm) + parseInt(form_data.age)) + " Math.min(40, (75-form_data.age)) " + Math.min(40, (75 - form_data.age)) + " userTerm : " + userTerm);
    const RiderDetails = [
      { //1
        "CarrierRiderDesc": "Accidental Disability Rider",
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "INAD",
        "Term": (parseInt(userTerm) + parseInt(form_data.age)) > 75 ? Math.min(40, (75 - form_data.age)) : userTerm,
        "SumInsured": riderSumInsured_ > 20000000 ? 20000000 : riderSumInsured_,
        "PPT": (parseInt(userTerm) + parseInt(form_data.age)) > 75 ? Math.min(40, (75 - form_data.age)) : userTerm,
        "PPTOption": "REGULAR",
        "cat": "HDFC"
      },

      { //2
        "CarrierRiderDesc": "Personal Accident Cover rider",
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "PPR_PAC", // 
        "Term": (parseInt(userTerm) + parseInt(form_data.age)) > 75 ? (75 - form_data.age) : userTerm,
        "SumInsured": riderSumInsured_ > 5000000 ? 500000 : riderSumInsured_,
        "PPT": (parseInt(userTerm) + parseInt(form_data.age)) > 75 ? Math.min(post_data.PPT, (75 - form_data.age)) : userTerm,
        "PPTOption": "REGULAR",
        "cat": "HDFC"
      },
      // OR 
      { // 3
        "CarrierRiderDesc": "Accidental death cover",
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "PPR_ADC", // 
        "Term": (userTerm + form_data.age) > 75 ? (75 - form_data.age) : userTerm,
        "SumInsured": riderSumInsured_ > 5000000 ? 500000 : riderSumInsured_,
        "PPT": (userTerm + form_data.age) > 75 ? Math.min(post_data.PPT, (75 - form_data.age)) : userTerm,
        "PPTOption": "REGULAR",
        "cat": "HDFC"
      },


      { // 4
        "CarrierRiderDesc": "Cancer Care rider",
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "HPR_CC",
        "Term": Math.min(40, userTerm),
        "SumInsured": riderSumInsured_ > 5000000 ? 5000000 : riderSumInsured_,
        "PPT": Math.min(40, post_data.PPT),
        "PPTOption": "REGULAR",
        "cat": "HDFC"
      },
      // OR
      { // 5
        "CarrierRiderDesc": "Comprehensive CI",
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "HPR_CCI",
        "Term": Math.min(40, userTerm),
        "SumInsured": riderSumInsured_ > 5000000 ? 5000000 : riderSumInsured_,
        "PPT": Math.min(40, post_data.PPT),
        "PPTOption": "REGULAR",
        "cat": "HDFC"
      },


      { // 6
        "CarrierRiderDesc": "Critical Illness Plus Rider",
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "C4CI",
        "Term": userTerm > 40 ? 40 : userTerm,
        "SumInsured": riderSumInsured_ > 15000000 ? 20000000 : 1000000,
        "PPT": userTerm > 40 ? 40 : userTerm,
        "PPTOption": "REGULAR",
        "cat": "HDFC"
      },

      // below for  ICICI
      { // 7
        "CarrierRiderDesc": "Accelerated Critical Illness (ACI) Benefit",
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "CIBF",
        "Term": userTerm,
        "SumInsured": form_data.SumInsured > 1000000 ? 2500000 : 1000000,
        "PPT": post_data.PPT,
        "PPTOption": "REGULAR",
        "cat": "ICICI"
      },
      { // 8
        "CarrierRiderDesc": "Accident Death Cover",
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "ADBU",
        "Term": userTerm,
        "SumInsured": riderSumInsured_,
        "PPT": post_data.PPT,
        "PPTOption": "REGULAR",
        "cat": "ICICI"
      }
    ];

    console.log('form_data.PPT', form_data.PPT);

    let pptOption = form_data.PPT === 1 ? "SINGLE" : form_data.PPT ? (userTerm !== form_data.PPT ? "LIMITED" : "REGULAR") : "REGULAR";

    /*  if(form_data.PPT === 1){
       pptOption = "SINGLE";
     }else if( form_data.PPT  !== userTerm){
       pptOption = "REGULAR";
     }else{
       // nothing 
     }
   */

    post_data.PPTOption = pptOption
    post_data.Education = form_data.education;


    // Update SumInsured in RiderDetails based on userInput
    const UpdatedRiderDetails = RiderDetails.map(detail => {
      if (getRiders.hasOwnProperty(detail.Rider)) {
        let s = getRiders[detail.Rider].toString();
        // s  = form_data.cover_till === 99 ? 0 : s;
        return { ...detail, SumInsured: s, PPTOption: pptOption };
      } else {
        return { ...detail, PPTOption: pptOption };
      }
    });

    console.log(" line no 255 All RiderDetails : ", RiderDetails, " UpdatedRiderDetails", UpdatedRiderDetails);

    post_data.LifeCoverOption = determineLifeCover();
    post_data.CarrierPlanId = determineLifeCover();
    post_data.CarrierPlanName = determineLifeCover();
    post_data.ProductCode = determineLifeCover();

    post_data.SumInsured = form_data.SumInsured;
    post_data.Term = (!isNaN(parseInt(c)) ? c : (60 - form_data.age));
    post_data.TOBstatus = form_data.smoked_status ? form_data.smoked_status : 'N';



    post_data.PolicyLobList[0].PolicyRiskList[0].DateOfBirth = db_date(form_data.dob);
    post_data.PolicyLobList[0].PolicyRiskList[0].Gender = form_data.gender;

    post_data.PolicyLobList[0].PolicyRiskList[0].Email = (form_data.email ? form_data.email : '');
    post_data.PolicyLobList[0].PolicyRiskList[0].Mobile = (form_data.mobile ? form_data.mobile : '');


    post_data.PolicyLobList[0].PolicyRiskList[0].OccupationCode = (form_data.occupation).toString();

    post_data.PolicyLobList[0].PolicyRiskList[0].City = form_data.city;
    post_data.PolicyLobList[0].PolicyRiskList[0].PostCode = form_data.pin;

    if(form_data.full_name){
      const [first, ...restname] = form_data.full_name.split(' ');

      post_data.PolicyLobList[0].PolicyRiskList[0].FirstName = first;
      post_data.PolicyLobList[0].PolicyRiskList[0].LastName = restname.join(' ');
    }



    post_data.AnnualIncome = form_data.annual_income;
    post_data.Frequency = form_data.PPT === 1 ? "5" : frequency;

    post_data.DBPayoutTerm = "0";
    post_data.LSPercentage = selectedDBOption === "4" ? "50" : "0";

    //post_data.PolicyLobList[0].RiderDetails = filteredRiderDetails;



    // Initialize an empty array to store keys with true values
    const trueKeyList = [];

    // Iterate over the input object
    for (const key in checkboxes) {
      if (checkboxes[key] === true) {
        trueKeyList.push(key); // LIST OF ALL CHECKED ADDONS 
      }
    }



    console.log("line no 360 =======>", updatedArr, '  checkboxes : ', checkboxes, 'trueKeyList', trueKeyList);
    setCheckedIciciAddon(trueKeyList);


    if (CarrierCode === 'ICICI') {
      post_data.DBPayoutTerm = DBPayoutTerm;

      console.log("trueKeyList: " + trueKeyList);

      console.log("frequency", frequency);
      // const UpdatedRiderDetails = UpdatedRiderDetails.filter(detail => trueKeyList.includes(detail.Rider));


      if (form_data.PPT === 1) {

        console.log("frequency: " + trueKeyList);
        // Update PPTOption to "SINGLE" for all elements in RiderDetails array
        //const updatedRiderDetailsPPT = UpdatedRiderDetails.map(detail => { return { ...detail, PPTOption: "SINGLE" }; });

        //post_data.PolicyLobList[0].RiderDetails = updatedRiderDetailsPPT.filter(detail => !uncheckedIciciAddon.includes(detail.Rider));

        post_data.PolicyLobList[0].RiderDetails = [];

      } else {
        post_data.PolicyLobList[0].RiderDetails = UpdatedRiderDetails.filter(detail => !uncheckedIciciAddon.includes(detail.Rider));
      }

      // HERE IS FILTER ADDONS ACCORING TO CHECKED ADDONS LIST 
      if (form_data.PPT === 1) {
        post_data.PolicyLobList[0].RiderDetails = [];
      }else{
        post_data.PolicyLobList[0].RiderDetails = UpdatedRiderDetails.filter(detail => trueKeyList.includes(detail.Rider));
      }
      

    } else {

      // for HDFC  - filter riderDetails List
      // new code
      //const filteredRiderDetails = UpdatedRiderDetails.filter(detail => ['INAD', 'PPR_PAC', 'PPR_CC', 'C4CI'].includes(detail.Rider));
      //old  code 


      //const filteredRiderDetails = UpdatedRiderDetails.filter(detail => updatedArr.includes(detail.Rider));
      const filteredRiderDetails = UpdatedRiderDetails.filter(detail => trueKeyList.includes(detail.Rider));

      console.log(' line no 300 frequency hdfc', frequency, " filteredRiderDetails", filteredRiderDetails);

      if (form_data.PPT === 1) {

        // Update PPTOption to "SINGLE" for all elements in RiderDetails array
        const updatedRiderDetailsPPT = filteredRiderDetails.map(detail => { return { ...detail, PPTOption: "SINGLE" }; });
        post_data.PolicyLobList[0].RiderDetails = updatedRiderDetailsPPT.filter(detail => !uncheckedIciciAddon.includes(detail.Rider));
      } else {
        post_data.PolicyLobList[0].RiderDetails = filteredRiderDetails.filter(detail => !uncheckedIciciAddon.includes(detail.Rider));
      }
      console.log(" line no 316 - filteredRiderDetails ", filteredRiderDetails, ' - uncheckedIciciAddon : ', uncheckedIciciAddon, 'updatedArr', updatedArr);

    }


    post_data.PPTOption = (form_data.cover_till === 99 ? 'REGULAR WHOLE LIFE' : post_data.PPTOption);

    // overwrite RiderDetails PPT value form base PPT In Case PPTOption = LIMITED and CarrierCode = HDFC
    if (post_data.PPTOption === 'LIMITED' && post_data.CarrierCode === 'HDFC') {
      // eslint-disable-next-line
      post_data.PolicyLobList[0].RiderDetails.map((v, i) => {
        //console.log(" base ppt " + post_data.PPT );
        post_data.PolicyLobList[0].RiderDetails[i].PPT = post_data.PPT;
      });
    }



    console.log('line no -  405::::: form_data after edit ', form_data, " | post_data: ", post_data);

    post_data.DBOption = selectedDBOption;
    /* post_data.DBPayoutTerm = 10; */

    let post = {
      form_data: form_data,
      api_post_data: post_data
    }

    /*  if(tryAgain){ // IF ACTION IS TRY AGAIN THEN RE-CALL PREVIUS POST 
       post = JSON.parse(localStorage.getItem('prev_request')); 
       localStorage.removeItem('prev_request'); 
       setTryAgain(false);
     } */

    // eslint-disable-next-line
    let data = [];
    http.post('/getQuote', post).then((response) => {

      const resp = response.data;
      console.log("line 340  :::: getQuote", resp);
      setLoading(false);

      sessionStorage.setItem('termpostData', JSON.stringify(post));
      sessionStorage.setItem('CarrierCode', CarrierCode);

      const single_data = resp.filter((obj) => obj.CarrierCode === CarrierCode)[0];
      console.log("single_data ", single_data);


      if (resp) {
        let err = 0;
        [single_data].forEach(error => {

          console.log('error.CarrierCode', CarrierCode, 'error', error);

          if (error.error_details && error.error_details.error_code && error.CarrierCode === CarrierCode) {
            setWarningshow(true);
            setWarningMessage(error.error_details.error_message);
            err = 1;
            return;
          }

          if (error.Error && error.CarrierCode && error.CarrierCode === CarrierCode) {
            // alert("line 365");
            setWarningshow(true);
            setWarningMessage(error.Error.ErrorMessage);
            err = 1;
            return;
          }

        });


        setQuoteNewData(resp.filter((obj) => obj.CarrierCode === CarrierCode)[0]) //====
        if (!err) {
          localStorage.setItem('prev_request', JSON.stringify(post));
        }


      } else if (resp.status === 401) {
        localStorage.removeItem('access_token');
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: resp.message,
        }).then(() => {
          window.location.href = Global.base_url;
        })

      } else if (resp[0].error_details) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: resp[0].error_details.error_message,
        })
      } else if (resp[1].Error && resp[1].Error.ErrorMessage) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: resp[1].Error.ErrorMessage,
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: JSON.stringify(resp),
        })
      }

    });


    // return data;
  }

  /* 
    const [tryAgain, setTryAgain] = useState(false);
    useEffect(()=>{
      setWarningshow(false);
  
      if(tryAgain){
        getQuoteNew(); 
      } 
    }, [tryAgain]) */

  const ContinueToBuy = () => {

    setLoading(true);

    window.location.href = '/term-thank-you';

    /*var post_data = '';
    if (CarrierCode === 'ICICI') {
      post_data = params_icici_life_getQuoteFinalised;
    } else {
      post_data = params_hdfc_life_getQuoteFinalised;
    }


    const form_data = localStorage.getItem('form_data_term_plan') ? JSON.parse(localStorage.getItem('form_data_term_plan'), (key, value) => {
      if (value === 'true' || value === true) return true;
      else if (value === 'false' || value === false) return false;
      else if (value === '') return '';
      else if (!isNaN(value)) return parseInt(value);
      return value;
    }) : {};

    const c = form_data.cover_till - form_data.age;

    const userTerm = (!isNaN(parseInt(c)) ? c : (60 - form_data.age));

    const RiderDetails = [
      {
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "INAD",
        "Term": userTerm,
        "SumInsured": riderSumInsured > 20000000 ? 20000000 : riderSumInsured,
        "PPT": userTerm,
        "PPTOption": "REGULAR"
      },
      {
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "C4CI",
        "Term": userTerm,
        "SumInsured": riderSumInsured > 15000000 ? 20000000 : 1000000,
        "PPT": userTerm,
        "PPTOption": "REGULAR"
      },
      {
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "PPR_PAC", // 
        "Term": userTerm,
        "SumInsured": riderSumInsured > 5000000 ? 5000000 : riderSumInsured,
        "PPT": userTerm,
        "PPTOption": "REGULAR"
      },
      {
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "PPR_CC", // HDFC CANCER COVER
        "Term": userTerm,
        "SumInsured": riderSumInsured > 5000000 ? 5000000 : riderSumInsured,
        "PPT": userTerm,
        "PPTOption": "REGULAR"
      },
      {
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "ADBU", // HDFC Accidental cover
        "Term": userTerm,
        "SumInsured": riderSumInsured,
        "PPT": userTerm,
        "PPTOption": "REGULAR"
      },
      {
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "CIBF", // HDFC critical illness benefit rider
        "Term": userTerm,
        "SumInsured": form_data.SumInsured > 1000000 ? 2500000 : 1000000,
        "PPT": userTerm,
        "PPTOption": "REGULAR"
      },
      {
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "ADHB", // ICICI Accidental cover
        "Term": userTerm,
        "SumInsured": riderSumInsured,
        "PPT": userTerm,
        "PPTOption": "REGULAR"
      }
    ];

    // Update SumInsured in RiderDetails based on userInput
    const UpdatedRiderDetails = RiderDetails.map(detail => {
      if (getRiders.hasOwnProperty(detail.Rider)) {
        return { ...detail, SumInsured: getRiders[detail.Rider].toString() };
      } else {
        return detail;
      }
    });


    post_data.LifeCoverOption = determineLifeCover();
    post_data.CarrierPlanId = determineLifeCover();
    post_data.CarrierPlanName = determineLifeCover();
    post_data.ProductCode = determineLifeCover();
    post_data.SumInsured = form_data.SumInsured;
    post_data.Term = (!isNaN(parseInt(c)) ? c : (60 - form_data.age));
    post_data.PPT = (!isNaN(parseInt(c)) ? c : (60 - form_data.age));
    post_data.TOBstatus = form_data.smoked_status ? form_data.smoked_status : 'N';

    post_data.PolicyLobList[0].PolicyRiskList[0].DateOfBirth = db_date(form_data.dob);
    post_data.PolicyLobList[0].PolicyRiskList[0].Gender = form_data.gender;

    post_data.PolicyLobList[0].PolicyRiskList[0].Email = (form_data.email ? form_data.email : '');
    post_data.PolicyLobList[0].PolicyRiskList[0].Mobile = (form_data.mobile ? form_data.mobile : '9882712357');

    post_data.AnnualIncome = form_data.annual_income;
    post_data.Frequency = frequency;
    post_data.PolicyLobList[0].RiderDetails = UpdatedRiderDetails;
    const post = {
      form_data: form_data,
      api_post_data: post_data
    }

    http.post('/getQuoteFinalised', post).then((response) => {

      //const resp = response.data;
      setLoading(false);
      //window.open(resp[0].journeyLink, "_blank");
      window.href = 'term-thank-you';

    });*/

  }

  const logo = (CarrierCode) => {
    switch (CarrierCode) {
      case 'HDFC':
        return 'images/logo/hdfc-life.png';
      case 'ICICI':
        return 'images/logo/iprotect-smart.png';
      default:
        return '';
    }
  }

  const [ebiClick, setEbiClick] = useState(false);

  const downloadEbi = (url) =>{
    setEbiClick(true);
    window.open(url, '_blank', 'noopener,noreferrer');
  }



  useEffect(() => {
    localStorage.setItem('form_data_term_plan', JSON.stringify({ ...form_data, frequency: frequency }));
  }, [form_data, frequency]);

  const [basePremium, setBasePremium] = useState();

  const [addonAmount, setAddonAmount] = useState(0);
  // eslint-disable-next-line
  const [selectedAddons, setSelectedAddons] = useState([]);

  useEffect(() => {

    setGetRidersNodes(getQuoteNewData.Riders);

    if (getQuoteNewData.PolicyLobList && getQuoteNewData.PolicyLobList[0]) {


      let sumInsuredSum = 0;
      if (getQuoteNewData.PolicyLobList[0].RiderDetails) {
        console.log("getQuoteNewData.PolicyLobList[0].RiderDetails ::", getQuoteNewData.PolicyLobList[0].RiderDetails);
        sumInsuredSum = getQuoteNewData.PolicyLobList[0].RiderDetails.reduce((acc, current) => acc + current.Premium + current.TotalTax, 0);
      } else {
        sumInsuredSum = 0;
      }
      console.log("sumInsuredSum : ", sumInsuredSum);
      setBasePremium(parseInt(getQuoteNewData.TotalPremium) - parseInt(sumInsuredSum));
      console.log("basePremium: ", basePremium);

      //setSelectedAddons(getQuoteNewData.PolicyLobList[0].RiderDetails.map(policy => policy.Rider));

      setAddonAmount(parseInt(sumInsuredSum));
    }

    // eslint-disable-next-line
  }, [getQuoteNewData])

  useEffect(() => {
    localStorage.setItem('form_data_term_plan', JSON.stringify(form_data));
  }, [form_data]);

  useEffect(() => {


    getQuoteNew()

    // eslint-disable-next-line
  }, [form_data, frequency])

  console.log('selectedAddons', selectedAddons);

  /*const handleBaseAmount = (e, matchRider) => {

    if (e.target.checked) {

      console.log('addonAmount plus', addonAmount, 'e.target.value plus', e.target.value);
      setAddonAmount(parseInt(addonAmount) + parseInt(e.target.value));
      setSelectedAddons([...selectedAddons, matchRider.Rider]);
    } else {
      console.log('addonAmount minus', addonAmount, 'e.target.value minus', e.target.value);
      setAddonAmount(parseInt(addonAmount) - parseInt(e.target.value));
      setSelectedAddons(selectedAddons.filter(item => item !== matchRider.Rider));
    }
  }*/

  // Function to determine life cover based on selected addons
  const determineLifeCover = () => {
    if (selectedAddons.length === 0) {
      return lifeCovers[0]; // Default to 'Life'
    } else if (selectedAddons.includes('ADBU') && selectedAddons.includes('CIBF')) {
      return lifeCovers[3]; // 'All in One'
    } else if (selectedAddons.includes('ADBU')) {
      return lifeCovers[1]; // 'Life Plus'
    } else if (selectedAddons.includes('CIBF')) {
      return lifeCovers[2]; // 'Life and Health'
    } else {
      return lifeCovers[0]; // Default to 'Life'
    }

  };

  /*const calculateTax = (amount) => {
    const taxRate = 0.18; // 18%
    const tax = amount * taxRate;
    return Math.ceil(tax);
  }*/

  const [selectedDBOption, setSelectedDBOption] = useState(2);

  const [DBPayoutTerm, setDBPayoutTerm] = useState("");


  const handleDBOptionChange = (event) => {
    setSelectedDBOption(event.target.value);
    console.log("selectedDBOption", selectedDBOption);
  };

  const handleDBPayoutTerm = (event) => {
    setDBPayoutTerm(event.target.value);
  };


  useEffect(() => {

    if (["2"].includes(selectedDBOption)) {
      setDBPayoutTerm("");
    } else if (["1", "3"].includes(selectedDBOption)) {
      setDBPayoutTerm(10);
    } else if (["4"].includes(selectedDBOption)) {
      setDBPayoutTerm(10);
      setFormData({ ...form_data, DBOption: selectedDBOption });
    } else {

    }
    // eslint-disable-next-line
  }, [selectedDBOption])


  useEffect(() => {
    setFormData({ ...form_data, DBOption: selectedDBOption, DBPayoutTerm: DBPayoutTerm });
    // eslint-disable-next-line
  }, [DBPayoutTerm])

  /* const [loaderTime, setLoaderTime] = useState(false);
  
  useEffect(()=>{
  
    if(!loaderTime){
      Swal.fire({
        position: "bottom-end",
        icon: "success",
        title: "Data fetched successfully", 
        //text: "Data fetched successfully", 
        showConfirmButton: false,
        timer: 2000  
      });
    }else{
      Swal.fire({
        position: "bottom-end",
        //icon: "success",
        text: "Data Fetching...", 
        showConfirmButton: false,
      //  timer: loaderTime  
      });
    } 
  }, [loaderTime]) */

  const redirectQuote = () =>{
    window.location.href = '/term-plan/quote';
  }

  return (
    <Fragment>
      <Title title={pageTitle} />
      <DashboardHeader />
      <div className="innercontainer mb40">
        <div className="mainwrapper">
          <div className="beadcrumbs font16 color626">
            <Link to={"#"}>Advisory</Link><i className="fa fa-angle-right font13" aria-hidden="true"></i>
            <Link to={"#"}>Insurance Advise</Link><i className="fa fa-angle-right font13" aria-hidden="true"></i>
            <Link to={"#"}>Term Insurance</Link><i className="fa fa-angle-right font13" aria-hidden="true"></i>
            <span>{getQuoteNewData && getQuoteNewData.CarrierProductName && getQuoteNewData.CarrierProductName}</span>
            {/*<Link to={"/term-plan/compare"} className="bluebtn" style={{ position: 'absolute', right: '95px', color: 'white' }}><i className="fa-solid fa-code-compare font13 mr5"></i> Compare</Link>*/}

            <Link to={"/term-plan/quote"} className="bluebtn" style={{ position: 'absolute', right: '9px', color: 'white' }}><i className="fa fa-angle-left font13 mr5" aria-hidden="true"></i> Back</Link>
          </div>
          {loading ? (
            <Loader /> // Render the loader when loading is true
          ) :
            <div className="row mt20 life-insurance-details">
              <div className="col-md-8">
                <div className="card p20">
                  <div className="row">
                    <div className="col-md-12 life-lnsurance-logo-section">

                      <table className="">
                        <tr>
                          <td style={{ 'maxWidth': '150px' }}><img src={Global.base_url + '/' + logo(getQuoteNewData.CarrierCode)} alt="" className="logo maxWidth100" /></td>
                          <td style={{ 'paddingLeft': "10px" }}>
                            <h2 className="heading-1"> {getQuoteNewData && getQuoteNewData.CarrierProductName && getQuoteNewData.CarrierProductName}  </h2>
                            {
                              packages.sub_tags &&
                              packages.sub_tags.map((tag) => {
                                return <span className="tags mr10">{tag}</span>
                              })
                            }
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <hr className="border-hr" />
                  <div className="card-body-text extra-benefits">
                    <h3 className="heading-2 mt10 mb10">Features</h3>
                    <div className="row">

                      {getQuoteNewData.Features && getQuoteNewData.Features.map((item, index) => (

                        <div className="col-md-6 item mobile-lp0 mobile-rp0-i" key={index}>
                          <h5 className="heading-3">{item.BenefitDescription}</h5>
                          <p className="text-type-1">
                            {item.Notes}
                          </p>
                        </div>

                      ))}
                    </div>
                  </div>
                </div>



                <div className="row mt20">
                  <div className="col-md-12">
                    <div className="card p20 mt20">
                      <div className="card-body-text extra-benefits">
                        <h3 className="heading-2 mt10 mb10">Free Benefits</h3>
                        <div className="row">
                          {getQuoteNewData.FreeBenefits && getQuoteNewData.FreeBenefits.map((item, index) => (
                            <div className="col-md-6 item mobile-lp0 mobile-rp0-i" key={index}>
                              <h5 className="heading-3">{item.BenefitDescription}</h5>
                              <p className="text-type-1">
                                {item.Notes}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt20">
                  <div className="col-md-12">
                    <div className="card p20 mt20">
                      <div className="card-body-text extra-benefits">
                        <h3 className="heading-2 mt10 mb10">Paid Benefits</h3>
                        <div className="row">
                          {getQuoteNewData.PaidBenefits && getQuoteNewData.PaidBenefits.map((item, index) => (
                            <div className="col-md-6 item mobile-lp0 mobile-rp0-i" key={index}>
                              <h5 className="heading-3">{item.BenefitDescription}</h5>
                              <p className="text-type-1">
                                {item.Notes}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb50"></div>

              </div> {/** end of left section col-md-8 */}

              <div className="col-md-4">{/** start of right section col-md-4 */}

                <div className="card p20 ">
                  <div className="row">
                    <div className="col-md-12">
                      <table>
                        <tr>
                          <td className="" style={{ width: '90%' }}>
                            <span className="text-type-1-1">Basic Info</span>
                            <br />
                            <span className="heading-3">{form_data.gender && get_gender(form_data.gender)} | {form_data.dob && ageCalculate(form_data.dob)} Years | {form_data.smoked_status && form_data.smoked_status === 'Y' ? 'Smoker' : 'Non-Smoker'} </span>
                          </td>
                          <td className="">
                            <div className="title_viewmore">
                              <Link title="Edit Basic Info Details" to="#" onClick={() => { setEditBasicInfoFlag(true) }}><span className="font12 fw600 color0d6 pointer"><div className="goaledit_icon ml4"></div>  </span> </Link>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className="pt20" style={{ width: '90%' }}>
                            <span className="text-type-1-1">Cover Upto</span>
                            <br />
                            <div className="loader" style={{ width: '40px' }}></div>
                            <span className="heading-3"> ₹ {form_data.SumInsured && inWords(form_data.SumInsured)} till age {form_data.cover_till && form_data.cover_till} Years</span>
                          </td>
                          <td className="pt20">
                            <div className="title_viewmoreLifeInsuranceDetails.jsx">
                              <Link title="Edit Cover Upto Details" to="#" onClick={() => { setEditPlanDetailsFlag(true) }}><span className="font12 fw600 color0d6 pointer"><div className="goaledit_icon ml4"></div>  </span> </Link>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className="pt20" style={{ width: '90%' }}>
                            <span className="text-type-1-1">Pay For</span>
                            <br />
                            <div className="loader" style={{ width: '40px' }}></div>
                            <span className="heading-3"> {getQuoteNewData.PPT ? (getQuoteNewData.PPT === "1" ? "Single Pay" : getQuoteNewData.PPT + "Years") : '_ Years'} </span>
                          </td>
                          <td className="pt20">
                            <div className="title_viewmore">
                              <Link title="Edit Pay For Details" to="#" onClick={() => { setEditPayForFlag(true) }}><span className="font12 fw600 color0d6 pointer"><div className="goaledit_icon ml4"></div>  </span> </Link>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className="pt20" style={{ width: '90%' }}>
                            <span className="text-type-1-1">Policy Term</span>
                            <br />
                            <div className="loader" style={{ width: '40px' }}></div>
                            <span className="heading-3"> {getQuoteNewData.Term} Years</span>
                          </td>
                          <td className="pt20">

                          </td>
                        </tr>

                        {/*
                          getQuoteNewData.CarrierCode === 'ICICI' &&
                          <><tr>
                            <td className="pt20" style={{ width: '90%' }}>
                              <span className="text-type-1-1">EBI PDF</span>
                              <br />
                              <div className="loader" style={{ width: '40px' }}></div>
                              <span className="heading-3"> <a href={getQuoteNewData.EBIPdfDigital} target="_blank" rel="noreferrer"><i class="fas fa-file-pdf fa-2x"></i></a> </span>
                            </td>
                            <td className="pt20">

                            </td>
                          </tr></>
                        */}

                        {
                          getQuoteNewData.CarrierCode === 'ICICI' &&
                          <><tr>
                            <td className="pt20" style={{ width: '90%' }}>
                              <span className="text-type-1-1">EBI PDF</span>
                              <br />
                              <div className="loader" style={{ width: '40px' }}></div>
                              <span className="heading-3"><i class="fas fa-file-pdf fa-2x" onClick={()=>{ downloadEbi(getQuoteNewData.EBIPdfDigital)}}></i> </span>
                            </td>
                            <td className="pt20">

                            </td>
                          </tr></>
                        }


                        <tr>
                          <td className="pt20" style={{ width: '90%' }}>
                            <span className="text-type-1-1">Payout Option</span>
                            {
                              getQuoteNewData.CarrierCode === 'ICICI' ?
                                <>
                                  <select className="premium-info-dropdown" value={selectedDBOption} onChange={handleDBOptionChange} name="DBOption" id="DBOption" style={{ 'position': 'absolute', 'right': '13px' }}>
                                    {
                                      DBOptionList.map(function (row, index) {
                                        return (<option value={row.value}>{row.label}</option>)
                                      })
                                    }
                                  </select>
                                  <br />
                                  <div className="loader" style={{ width: '40px' }}></div>
                                </>
                                :
                                <>
                                  <br />
                                  <div className="loader" style={{ width: '40px' }}></div>
                                  {<span className="heading-3"> Lumpsum ( All at one time) </span>}
                                </>
                            }
                          </td>
                        </tr>

                        {
                          (getQuoteNewData.CarrierCode === 'ICICI' && ["1", "3", "4"].includes(selectedDBOption)) &&
                          <><tr>
                            <td className="pt20" style={{ width: '90%' }}>
                              <span className="text-type-1-1"> DB Payout Term</span>
                              <select className="premium-info-dropdown" value={DBPayoutTerm} onChange={handleDBPayoutTerm} name="DBOption" id="DBOption" style={{ 'position': 'absolute', 'right': '13px' }}>
                                <option value="10">10</option>
                                {
                                  selectedDBOption !== "3" && <> <option value="20">20</option> <option value="30">30</option> </>
                                }

                              </select>
                            </td>
                          </tr>
                          </>
                        }


                      </table>
                    </div>
                  </div>
                </div>

                {
                  CarrierCode === 'HDFC' && (
                    <>
                      <div className="card p20 mt20">
                        <div className="row">
                          <div className="col-md-12">
                            <table>
                              <tr>
                                <td className="" style={{ width: '90%' }}>
                                  <span className="text-type-1-1">Paid Benefits Addons</span>
                                  <br />
                                  <br />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <label class="text-type-2"><input type="checkbox" name="ROP" value="No" checked={ropChecked} onChange={handleCheckboxChange(setRopChecked)} /> Return of Premium (ROP) </label>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <label class="text-type-2"><input type="checkbox" name="WOPCI" value="No" checked={wopciChecked} onChange={handleCheckboxChange(setWopciChecked)} /> Waiver of Premium on CI (WOP CI) </label>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <label class="text-type-2"><input type="checkbox" name="WOPTotDisability" value="No" checked={woptdChecked} onChange={handleCheckboxChange(setWoptdChecked)} /> Waiver of Premium on Total and Permanent Disability (WOP Disability)  </label>
                                </td>
                              </tr>

                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }




                <div className="card p20 mt20">
                  <div className="row">
                    <div className="col-md-12">


                      <table className="full-width">
                        <tr>
                          <td className="height40" style={{ width: '60%' }}>
                            <span className="heading-3">Premium Info</span>
                          </td>
                          <td className="text-right">
                            <select className="premium-info-dropdown" onChange={(e) => { setFrequency(e.target.value) }}>
                              {
                                paymentFrequencyList.map(function (row, index) {
                                  return (<option value={row.value}>Pay {row.label}</option>)
                                })

                              }

                            </select>
                          </td>
                        </tr>

                        <tr>
                          <td className="height40">
                            <span className="heading-3">Premium Amount</span>
                          </td>
                          <td className="text-right ">
                            <span className="btn-display-amount-2"> ₹  {basePremium && formatAmount(parseFloat(basePremium))}</span>
                          </td>
                        </tr>
                      </table>

                      <hr className="border-hr mt20" />

                      <table className="full-width details-page-add-ons">
                        <tr>
                          <td className="height30" style={{ width: '60%' }}>
                            <span className="heading-3">Add ons</span>
                          </td>
                          <td className="text-right" style={{ width: '40%' }}>
                            <div className="title_viewmore">
                              <Link title="Edit Add-Ons Details" to="#" onClick={() => { setEditAddOnsFlag(true) }}><span className="font12 fw600 color0d6 pointer"><div className="goaledit_icon ml4"></div>  </span> </Link>
                            </div>
                          </td>
                        </tr>
                        {
                          getQuoteNewData &&
                          getQuoteNewData.PolicyLobList &&
                          getQuoteNewData.PolicyLobList[0].RiderDetails &&
                          getQuoteNewData.PolicyLobList[0].RiderDetails.map((value, index) => {


                            return value.Premium > 0 ? <tr>

                              <td className="height50">
                                {/* onClick={(e) => handleBaseAmount(e, MatchRider ? MatchRider : {})}*/}
                                <label className="text-type-2"><input type="checkbox" name={"rider_code_" + value.Rider} addon-tax={value.TotalTax} defaultValue={(value.Premium + value.TotalTax)} checked={checkedIciciAddon.includes(value.Rider) ? true : false} readOnly={true} disabled={true} /> <GetRiderDescription riderCode={value.Rider} /> </label>
                                <div className="text-type-3 ml20 p">(cover {inWords(value.SumInsured)})</div>
                              </td>
                              <td className="text-right">
                                <span className="btn-display-amount-2"> ₹ {formatAmount((value.Premium + value.TotalTax))}
                                </span>
                              </td>
                            </tr>
                              : <></>
                          })
                        }

                        <tr>
                          <td colSpan="2" className="height30"><hr className="border-hr" /></td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="pt10 ">
                            <div className="total-to-pay-area" style={{ 'lineHeight': '1.5' }}>
                              <span>Total to pay (incl. of taxes) </span> <span className="ml20 bold">₹ {formatAmount(parseInt(basePremium) + parseInt(addonAmount))} </span>
                            </div>
                            <div className="text-center  mt5 mb20"></div>
                            {
                              (ebiClick || getQuoteNewData.CarrierCode === 'HDFC') ? <input type="button" className="btn btn-primary continue-to-buy-btn " value="Continue to buy" onClick={() => { ContinueToBuy(getQuoteNewData.journeyLink) }} /> : <input type="button" className="btn btn-primary continue-to-buy-btn " value="Download EBI to Continue" onClick={() => { downloadEbi(getQuoteNewData.EBIPdfDigital) }} />
                            }
                            
                          </td>
                        </tr>

                      </table>

                    </div>
                  </div>
                </div>
                <Assistant className="mt30" />

              </div>
            </div>
          } {/* end of loader */}
        </div>
      </div>


      {
        editBasicInfoFlag && <EditBasicInfo setEditBasicInfoFlag={setEditBasicInfoFlag} setFormData={setFormData} form_data={form_data} />
      }

      {
        editPlanDetailsFlag && <EditPlanDetails setEditPlanDetailsFlag={setEditPlanDetailsFlag} setFormData={setFormData} form_data={form_data} />
      }

      {

        editPayForFlag && <EditPayFor getQuoteNewData={getQuoteNewData} setFormData={setFormData} form_data={form_data} setEditPayForFlag={setEditPayForFlag} setRiderSumInsured={setRiderSumInsured} />
      }

      {
        editAddOnsFlag && <EditAddOns setEditAddOnsFlag={setEditAddOnsFlag} setFormData={setFormData} form_data={form_data} getQuoteNewData={getQuoteNewData} setRiders={setRiders} getRiders={getRiders} riderDetails={getQuoteNewData.PolicyLobList[0].RiderDetails} accidentAddonArr={accidentAddonArr} setAccidentAddon={setAccidentAddon} checkedIciciAddon={checkedIciciAddon} setCheckedIciciAddon={setCheckedIciciAddon} uncheckedIciciAddon={uncheckedIciciAddon} setUncheckedIciciAddon={setUncheckedIciciAddon}

          checkboxes={checkboxes} setCheckboxes={setCheckboxes}
        />
      }

      <Modal show={warningshow} size="lg" dialogClassName='modal-container' keyboard centered>
        <ModalBody>
          <div className="p20">
            <div className="text-left">
              <img src='../../images/error_message.png' alt='Error' width={"80"} />
            </div>
            <div className="text-left mt40">
              <h1 className='font24 fw700 color384'>{warningTitle}</h1>
            </div>
            <div className="text-left mt40 color485 fw500 font17">
              <strong>{warningMessage}</strong>
            </div>


            <div className="text-center smallbtn popupConsultBtn mt30">
              <button type="button" className="btn btn-danger btn-xl" onClick={() => { /* setTryAgain(true) */  window.location.reload() }}><i class="fa fa-refresh faErrorClass" aria-hidden="true"></i> Try Again</button>
              <button type="button" disabled={false} className="btn btn-success btn-xl" onClick={() => { setWarningshow(false); redirectQuote(); }}><i class="fa fa-times faErrorClass" aria-hidden="true"></i>
                Close </button>
            </div>

          </div>
        </ModalBody>
      </Modal>

    </Fragment>
  );
}
